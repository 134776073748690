<template>
    <div class="relative w-full col-flex h-[calc(100vh-180px)]">
        <div class="w-full h-full absolute inset-0 z-1">
            <NuxtImg
                :src="getThumbnail(dataStyleDetail[0]?.thumbnail, 1920, true)"
                width="1920"
                sizes="sm:100vw md:50vw lg:1920px "
                loading="lazy"
                :alt="dataBanner?.banners?.banner_items[0]?.caption_title"
                :placeholder="[50, 25, 75, 5]"
                class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
            />
        </div>
        <div class="absolute w-full h-full inset-0 z-10 bg-overlay-01">
            <div class="container w-full h-full">
                <div class="flex flex-col justify-center h-full">
                    <global-breadcrumb :breadcrumb="breadcrumb" class="flex mb-3" />
                    <div class="text-2xl sm:text-35px lg:text-5xl text-white font-medium mb-5">
                        {{ dataStyleDetail[0]?.title }}
                    </div>
                    <div class="text-white text-[18px] !leading-[144.444%] font-medium">
                        {{ dataStyleDetail[0]?.intro }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-12 lg:pt-24">
        <div class="container">
            <div class="max-w-[957px] mx-auto">
                <div class="text-blacks-90 text-base lg:text-xl !leading-[1.5] font-medium">
                    {{ dataStyleDetail[0]?.description }}
                </div>
            </div>
        </div>
    </div>
    <div class="py-12 lg:py-24">
        <div class="max-w-[1440px] px-15px xl:px-0 mx-auto">
            <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-x-6 lg:gap-x-8 gap-y-10 xl:gap-y-31">
                <div v-for="item in dataStyleDetail[0]?.style_items" class="">
                    <div class="h-[300px] xl:h-[459px] w-full mb-3">
                        <NuxtImg
                            :src="getThumbnail(item?.image, 688, true)"
                            width="688"
                            sizes="sm:100vw md:50vw lg:688px "
                            loading="lazy"
                            :alt="item?.banners?.banner_items[0]?.caption_title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                        />
                    </div>
                    <div class="text-sm text-blacks-90 !leading-[200%] font-medium">{{ item?.description }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="py-12 lg:py-24 bg-[#F8F8F8]">
        <div class="container">
            <div class="flex justify-between items-center">
                <h2 class="text-blacks-100 text-2xl sm:text-35px lg:text-5xl font-medium !leading-[133.333%]">
                    <v-text option_key="style_idea_title" />
                </h2>
                <nuxt-link
                    to="/thu-vien"
                    class="hidden lg:flex btn-effect-dark w-max items-center gap-3 text-white bg-blacks-100 py-4 px-9 cursor-pointer"
                >
                    <p>Xem tất cả</p>
                </nuxt-link>
            </div>
            <div
                class="max-w-[957px] text-base lg:text-[18px] !leading-[144.444%] font-medium text-blacks-100 mt-5 mb-6 lg:mb-12"
            >
                <v-text option_key="style_idea_subtitle" />
            </div>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 lg:gap-12">
                <nuxt-link :to="item?.url" v-for="item in dataStyleDetail[0]?.related_galleries" class="">
                    <div class="relative h-[400px] xl:h-[497px] w-full mb-4 lg:mb-8 overflow-hidden group">
                        <NuxtImg
                            :src="getThumbnail(item?.thumbnail, 560, true)"
                            width="560"
                            sizes="sm:100vw md:50vw lg:560px "
                            loading="lazy"
                            :alt="item?.title"
                            :placeholder="[50, 25, 75, 5]"
                            class="w-full h-full object-cover transform duration-250 group-hover:scale-115"
                        />
                        <div
                            @click="isShowVideo = true"
                            v-if="item?.type === 'video'"
                            class="absolute w-full h-full inset-0 bg-overlay duration-300 cursor-pointer flex justify-center items-center"
                        >
                            <img
                                src="/images/home/collection/play-video.svg"
                                alt="icon-play-video"
                                class="w-14 h-14 object-contain"
                            />
                        </div>
                    </div>
                    <div class="text-base lg:text-2xl font-medium text-blacks-100">
                        {{ item?.title }}
                    </div>
                    <div
                        v-if="isShowVideo && item?.type === 'video'"
                        class="fixed w-[90%] h-[400px] sm:w-[80%] sm:h-[90vh] top-1/2 left-1/2 transform -translate-1/2 z-1002"
                    >
                        <video
                            class="w-full h-full aspect-16/9 object-cover"
                            frameborder="0"
                            allowfullscreen
                            allow="autoplay"
                            controls
                        >
                            <source
                                :src="getThumbnail(item?.video, 375, true)"
                                class="w-full h-full"
                                type="video/mp4"
                            />
                            Your browser does not support HTML video.
                        </video>
                    </div>
                </nuxt-link>
            </div>
            <nuxt-link
                to="/thu-vien"
                class="flex lg:hidden btn-effect-dark w-max items-center gap-3 text-white bg-blacks-100 py-4 px-9 cursor-pointer mt-8 mx-auto"
            >
                <p>Xem tất cả</p>
            </nuxt-link>
        </div>
        <div
            v-if="isShowVideo"
            @click="isShowVideo = false"
            class="fixed w-full h-full inset-0 z-1001 bg-overlay-02"
        ></div>
    </div>
</template>
<script setup lang="ts">
const isShowVideo = ref(false)
const { getStyleDetailBySlug } = useInspiration()
const dataStyleDetail = await Promise.all([getStyleDetailBySlug({ slug: useRoute().params.slug })])
const breadcrumb = ref([
    {
        url: '/',
        title: 'Trang chủ'
    },
    {
        url: '/truyen-cam-hung',
        title: 'Truyền cảm hứng'
    },
    {
        url: dataStyleDetail[0]?.url,
        title: dataStyleDetail[0]?.title
    }
])
</script>
